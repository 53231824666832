import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import axios from './plugins/axios';
import Toast from "vue-toastification";
import type { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

import './assets/style.scss';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(axios, { baseUrl: import.meta.env.VITE_API_URL });

const options: PluginOptions = {
    // You can set your default options here
};

app.use(Toast, options);

app.mount('#app');
