<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="message"
                    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <Component
                                    :is="notificationIcon"
                                    class="h-6 w-6"
                                    :class="notificationColor"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900 capitalize">
                                    {{ message.type }}
                                </p>
                                <p class="mt-1 text-sm text-gray-500">{{ message.message }}</p>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button
                                    type="button"
                                    @click="closeNotification"
                                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { Ref } from 'vue';
    import type { FlashMessage } from '@/types/flash-message';

    import { XMarkIcon } from '@heroicons/vue/20/solid';
    import {
        CheckCircleIcon,
        ExclamationCircleIcon,
        InformationCircleIcon,
        XCircleIcon
    } from '@heroicons/vue/24/outline';

    import { computed, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useFlashMessage } from '@/stores/flash-message';
    import { FlashMessageType } from '@/types/flash-message';


    const flashMessage = useFlashMessage();
    const message: Ref<FlashMessage | undefined> = ref(undefined);

    const route = useRoute();
    watch(route, () => {
        if (flashMessage.hasAny()) {
            message.value = flashMessage.last();

            setTimeout(() => {
                closeNotification();
            }, 5000);
        }
    });

    const closeNotification = () => {
        message.value = undefined;
        flashMessage.clear();
    };

    const notificationIcon = computed(() => {
        if (message.value?.type === FlashMessageType.Warning) {
            return ExclamationCircleIcon;
        }

        if (message.value?.type === FlashMessageType.Error) {
            return XCircleIcon;
        }

        if (message.value?.type === FlashMessageType.Info) {
            return InformationCircleIcon;
        }

        return CheckCircleIcon;
    });

    const notificationColor = computed(() => {
        if (message.value?.type === FlashMessageType.Warning) {
            return 'text-yellow-400';
        }

        if (message.value?.type === FlashMessageType.Error) {
            return 'text-red-400';
        }

        if (message.value?.type === FlashMessageType.Info) {
            return 'text-blue-400';
        }

        return 'text-green-400';
    });
</script>
