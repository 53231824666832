import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore } from '@/stores/auth';
import type { Router } from 'vue-router';

const router: Router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // Auth routes
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/Auth/Login.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('../views/Auth/ForgotPassword.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('../views/Auth/ResetPassword.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/activate',
            name: 'activate-user',
            component: () => import('../views/Auth/ActivateUser.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/',
            name: 'dashboard',
            component: () => import('../views/Dashboard.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // User routes
        {
            path: '/users',
            name: 'users',
            component: () => import('../views/Users/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/users/new',
            name: 'new-user',
            component: () => import('../views/Users/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/users/:id',
            name: 'edit-user',
            component: () => import('../views/Users/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Product routes
        {
            path: '/products',
            name: 'products',
            component: () => import('../views/Products/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/products/taxonomy/:id',
            name: 'product-taxonomy',
            component: () => import('../views/Products/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/products/new',
            name: 'new-product',
            component: () => import('../views/Products/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/products/:product',
            name: 'product-details',
            component: () => import('../views/Products/Single.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/products/:product/variant/new',
            name: 'new-variant',
            component: () => import('../views/Products/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/products/:product/variant/:variant',
            name: 'edit-variant',
            component: () => import('../views/Products/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Product attribute routes
        {
            path: '/attributes',
            name: 'attributes',
            component: () => import('../views/Attributes/Index.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/attribute/new',
            name: 'new-attribute',
            component: () => import('../views/Attributes/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/attribute/:id',
            name: 'edit-attribute',
            component: () => import('../views/Attributes/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        // Taxonomy routes
        {
            path: '/taxonomies',
            name: 'taxonomies',
            component: () => import('../views/Taxonomies/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/taxonomies/:id',
            name: 'edit-taxonomy',
            component: () => import('../views/Taxonomies/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Stock location routes
        {
            path: '/locations',
            name: 'locations',
            component: () => import('../views/Locations/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/locations/new',
            name: 'new-location',
            component: () => import('../views/Locations/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/locations/:id',
            name: 'edit-location',
            component: () => import('../views/Locations/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pickup-locations',
            name: 'pickup-locations',
            component: () => import('../views/PickupLocations/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pickup-locations/new',
            name: 'new-pickup-location',
            component: () => import('../views/PickupLocations/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pickup-locations/:id',
            name: 'edit-pickup-location',
            component: () => import('../views/PickupLocations/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Customer routes
        {
            path: '/customers',
            name: 'customers',
            component: () => import('../views/Customers/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customers/new',
            name: 'new-customer',
            component: () => import('../views/Customers/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customers/:id',
            name: 'edit-customer',
            component: () => import('../views/Customers/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Order routes
        {
            path: '/orders',
            name: 'orders',
            component: () => import('../views/Orders/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders/new',
            name: 'new-order',
            component: () => import('../views/Orders/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders/:id',
            name: 'single-order',
            component: () => import('../views/Orders/Single.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orders/:id/edit',
            name: 'edit-order',
            component: () => import('../views/Orders/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Tax categories routes
        {
            path: '/tax-categories',
            name: 'tax-categories',
            component: () => import('../views/TaxCategories/Index.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/tax-categories/new',
            name: 'new-tax-category',
            component: () => import('../views/TaxCategories/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/tax-categories/:id',
            name: 'edit-tax-category',
            component: () => import('../views/TaxCategories/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        // Tax rates routes
        {
            path: '/tax-rates',
            name: 'tax-rates',
            component: () => import('../views/TaxRates/Index.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/tax-rates/new',
            name: 'new-tax-rate',
            component: () => import('../views/TaxRates/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/tax-rates/:id',
            name: 'edit-tax-rate',
            component: () => import('../views/TaxRates/Cru.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        // Channel routes
        {
            path: '/channels',
            name: 'channels',
            component: () => import('../views/Channels/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/channels/new',
            name: 'new-channel',
            component: () => import('../views/Channels/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/channels/:id',
            name: 'edit-channel',
            component: () => import('../views/Channels/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        // Coupon routes
        {
            path: '/coupons',
            name: 'coupons',
            component: () => import('../views/Coupons/Index.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/coupons/new',
            name: 'new-coupon',
            component: () => import('../views/Coupons/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/coupons/:id',
            name: 'edit-coupon',
            component: () => import('../views/Coupons/Cru.vue'),
            meta: {
                requiresAuth: true
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();

    // Require authentication for routes that need it
    if (!authStore.isLoggedIn && to.meta.requiresAuth) {
        next({ name: 'login' });
        return;
    }

    if (authStore.isLoggedIn && !to.meta.requiresAuth) {
        next('/');
        return;
    }

    next();
});

export default router;
