<template>
    <div class="fixed h-full w-72 flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 py-4">
        <div class="flex shrink-0 items-center">
            <img class="w-full" src="@/assets/logo.svg" alt="ProPyro" />
        </div>
        <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                            <button
                                v-if="!item.children"
                                @click="$router.push({ name: item.href })"
                                :class="[
                                    item.href === $route.name
                                        ? 'bg-gray-800 text-white'
                                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'w-full transition duration-100 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                ]"
                            >
                                <component
                                    :is="item.icon"
                                    class="h-6 w-6 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                />
                                {{ item.name }}
                            </button>
                            <Disclosure as="div" v-else v-slot="{ open }">
                                <DisclosureButton
                                    :class="[
                                        item.href === $route.name
                                            ? 'bg-gray-800 text-white'
                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                        'transition duration-100 flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold '
                                    ]"
                                >
                                    <component
                                        :is="item.icon"
                                        class="h-6 w-6 shrink-0 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    {{ item.name }}
                                    <ChevronRightIcon
                                        :class="[
                                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                            'ml-auto h-5 w-5 shrink-0'
                                        ]"
                                        aria-hidden="true"
                                    />
                                </DisclosureButton>
                                <DisclosurePanel as="ul" class="mt-1 px-2">
                                    <li v-for="subItem in item.children" :key="subItem.name">
                                        <button
                                            @click="$router.push({ name: subItem.href })"
                                            :class="[
                                                subItem.href === $route.name
                                                    ? 'bg-gray-800 text-white'
                                                    : 'transition duration-100 text-gray-400 hover:text-white hover:bg-gray-800',
                                                'block w-full text-left rounded-md py-2 pr-2 pl-9 text-sm leading-6 font-semibold'
                                            ]"
                                        >
                                            {{ subItem.name }}
                                        </button>
                                    </li>
                                </DisclosurePanel>
                            </Disclosure>
                        </li>
                    </ul>
                </li>
                <li class="mt-auto -mx-2">
                    <button
                        @click="logout"
                        class="w-full text-left text-danger hover:text-white hover:bg-gray-800 transition duration-100 flex gap-x-3 group rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                        <ArrowLeftOnRectangleIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
                        Uitloggen
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
    import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
    import { ChevronRightIcon } from '@heroicons/vue/20/solid';
    import {
        CreditCardIcon,
        ArchiveBoxIcon,
        ArrowLeftOnRectangleIcon,
        BanknotesIcon,
        BuildingStorefrontIcon,
        CogIcon,
        HomeIcon,
        UsersIcon,
        RectangleGroupIcon,
        UserCircleIcon
    } from '@heroicons/vue/24/outline';

    import { useAuthStore } from '@/stores/auth';
    import { useRouter } from 'vue-router';
    import type { NavigationItem } from '@/types/navigation-item';

    const authStore = useAuthStore();
    const router = useRouter();

    const logout = () => {
        authStore.clear();
        router.push({ name: 'login' });
    };

    const navigation: NavigationItem[] = [
        { name: 'Dashboard', href: 'dashboard', icon: HomeIcon },
        {
            name: 'Catalogus',
            icon: ArchiveBoxIcon,
            children: [
                { name: 'Producten', href: 'products' },
                { name: 'Categorieën', href: 'taxonomies' },
                { name: 'Attributen', href: 'attributes' }
            ]
        },
        {
            name: 'Locaties',
            icon: BuildingStorefrontIcon,
            children: [
                { name: 'Voorraadlocaties', href: 'locations' },
                { name: 'Pickup locaties', href: 'pickup-locations' }
            ]
        },
        {
            name: 'Verkoop',
            icon: CreditCardIcon,
            children: [
                { name: 'Bestellingen', href: 'orders' },
                { name: 'Coupons', href: 'coupons' },
            ]
        },
        {
            name: 'Belasting',
            icon: BanknotesIcon,
            children: [
                { name: 'Belastingcategorieën', href: 'tax-categories' },
                { name: 'Belastingtarieven', href: 'tax-rates' }
            ]
        },
        { name: 'Klanten', href: 'customers', icon: UsersIcon },
        { name: 'Kanalen', href: 'channels', icon: RectangleGroupIcon },
        { name: 'Gebruikers', href: 'users', icon: UserCircleIcon },
        { name: 'Instellingen', href: '', icon: CogIcon }
    ];
</script>
