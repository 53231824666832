import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import { DateTime } from 'luxon';

import { computed } from 'vue';

import type { AuthResponse } from '@/types/api/response/auth';

export const useAuthStore = defineStore('auth', () => {
    const token = useStorage('token', '');
    const role = useStorage('role', '');
    const expiresAt = useStorage('expiresAt', '');

    const isLoggedIn = computed(() => !!token.value && expiresIn() > 0);
    const isAdmin = computed(() => role.value === 'ROLE_ADMIN');
    const isNearlyExpired = computed(() => expiresIn() < 900);
    const isExpired = computed(() => expiresIn() <= 0);

    /**
     * Set the auth session.
     *
     * @param {AuthResponse} response
     */
    function set(response: AuthResponse) {
        token.value = response.token;
        role.value = response.role;
        expiresAt.value = response.expiresAt;
    }

    /**
     * Clear the session.
     */
    function clear(): void {
        token.value = '';
        role.value = '';
        expiresAt.value = '';
    }

    /**
     * Number of seconds in which the token expires.
     */
    function expiresIn(): number {
        if (!expiresAt.value) {
            return 0;
        }

        return (
            DateTime.fromISO(expiresAt.value).diff(DateTime.now(), 'seconds').toObject().seconds ??
            0
        );
    }

    return { token, role, expiresAt, isAdmin, isLoggedIn, isNearlyExpired, isExpired, set, clear, expiresIn };
});
