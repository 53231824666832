import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import { useAuthStore } from '@/stores/auth';
import { useFlashMessage } from '@/stores/flash-message';
import router from '../router';
import type { AuthResponse } from '@/types/api/response/auth';
import { FlashMessageType } from '@/types/flash-message';

const abortController = new AbortController();

/**
 * Auth request interceptor.
 *
 * Injects the Authorization header and refreshes the auth token when it is due to expire.
 *
 * @param config
 */
async function authRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
    const authStore = useAuthStore();
    const flashMessage = useFlashMessage();

    if (authStore.isLoggedIn === false) {
        return config;
    }

    // Hook up the abort controller so we can cancel the request.
    config.signal = abortController.signal;

    // Set authorization header.
    config.headers.Authorization = 'Bearer ' + authStore.token;

    // Refresh auth token.
    if (authStore.isNearlyExpired && config.url?.endsWith('/auth/refresh') !== true) {
        try {
            const refreshResponse: AxiosResponse<AuthResponse> = await axios.post(
                '/admin/auth/refresh'
            );

            authStore.set(refreshResponse.data);
        } catch (ex: unknown) {
            if (ex instanceof AxiosError) {
                if (!ex.status || ex.status < 400 || ex.status >= 500) {
                    return config;
                }

                // Set error message.
                // TODO: i18n
                flashMessage.add({ type: FlashMessageType.Error, message: 'Sessie is verlopen.' });

                // Abort request we were doing.
                abortController.abort();

                // Expire session and redirect to login page.
                authStore.clear();

                await router.push({ name: 'login' });

                return config;
            }
        }
    }

    return config;
}

/**
 * Session expired response interceptor.
 *
 * @param error
 */
async function sessionExpiredResponseInterceptor(error: any) {
    if (error instanceof AxiosError === false) {
        return;
    }

    // Only handle 401 and 403.
    if (error.response?.status !== 401 && error.response?.status !== 403) {
        return Promise.reject(error.response);
    }

    const authStore = useAuthStore();

    // If we get a 403, make sure our session has indeed expired.
    if (error.response?.status === 403 && authStore.isExpired === false) {
        return Promise.reject(error.response);
    }

    const flashMessage = useFlashMessage();

    // Set error message.
    // TODO: i18n
    flashMessage.add({ type: FlashMessageType.Error, message: 'Sessie is verlopen.' });

    // Expire session and redirect to login page.
    authStore.clear();

    await router.push({ name: 'login' });

    return Promise.reject(error.response);
}

export default {
    install(app: any, options: { baseUrl?: string }) {
        if (options.baseUrl) axios.defaults.baseURL = options.baseUrl;

        // Add request interceptors.
        axios.interceptors.request.use((config) => {
            return config;
        });

        axios.interceptors.request.use(authRequestInterceptor);

        // Add response interceptors.
        axios.interceptors.response.use(null, sessionExpiredResponseInterceptor);

        axios.defaults.showLoader = true;

        app.provide('axios', axios);
    }
};
